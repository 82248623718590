@use "../" as able;

.able-Checkbox {
  @include able.Checkbox();

  &--Comfortable {
    @include able.Checkbox('Comfortable');
  }

  &--Comfortable--Icon {
    @include able.Checkbox('Comfortable', 'Icon');
  }

  &--Comfortable--Pictogram {
    @include able.Checkbox('Comfortable', 'Pictogram');
  }

  &--Default--Icon {
    @include able.Checkbox('Comfortable', 'Icon');
  }

  &--Default--Pictogram {
    @include able.Checkbox('Comfortable', 'Pictogram');
  }
}
