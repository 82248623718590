@use "../" as able;

/*
  Dialog styles
*/

.able-Dialog {
  @include able.Dialog();
}

.able-Dialog--Destructive {
  @include able.Dialog(Destructive);
}

.able-Dialog--show--false {
  display: none;
}

.able-Dialog__overlay {
  display: none;
}
