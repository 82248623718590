@use "../" as able;

@each $name in able.$Surface-aliases {
  .able-Surface--#{$name} {
    @include able.Surface($name);
  }

  .able-Surface--#{$name}--interactive {
    @if $name != SurfaceTop {
      @include able.Surface($name, true);
    }
  }
}
