@use "../" as able;

.able-TextArea {
  @include able.TextArea();

  &--Tiny {
    @include able.TextArea("Tiny");
  }

  &--Short {
    @include able.TextArea("Short");
  }

  &--Default {
    @include able.TextArea("Default");
  }

  &--Long {
    @include able.TextArea("Long");
  }
}
